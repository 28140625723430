import Vue from 'vue'
import Vuex from 'vuex'
import userInfo from './modules/userInfo'
import knowledge from './modules/knowledge'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  getters: {
    token: state => state.userInfo.token,
    userInfo: state => state.userInfo.userInfo,
    historyList: state => state.knowledge.historyList,
    categoryObj: state => state.knowledge.categoryObj,
    categories: state => {
      let arr = []
      if (state.knowledge.categoryObj) {
        const values = Object.values(state.knowledge.categoryObj)
        if (values.length > 0) {
          arr = values.map(item => {
            const { code, name, id, list } = item
            return {
              code,
              name,
              id,
              list,
              icon: `icon_${code}.png`
            }
          })
        }
      }
      return arr
    }

  },
  modules: {
    userInfo,
    knowledge
  }
})
