<template>
  <transition name="fade">
    <div v-if="isShow" class="message">
      <!--&lt;!&ndash;<i class="iconfont"></i>&ndash;&gt;  需要icon可以把这里打开-->
      <span>{{ message }}</span>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Message',
  data() {
    return {
      message: '',
      time: 3000,
      isShow: true
    }
  },
  mounted() {
    this.close()
  },
  destroyed() {
    clearTimeout(this.timer)
  },
  methods: {
    close() {
      this.timer = setTimeout(() => {
        this.isShow = false
      }, this.time)
    }
  }
}
</script>

<style scoped lang="less">
  .message {
    font-size: .24rem;
    padding: .1rem .15rem .12rem;
    background: rgba(0,0,0,.75);
    position: fixed;
    top: 2.82rem;
    left:50%;
    transform: translate(-50%);
    color: rgba(255,255,255,1);
    border-radius: .1rem;
    z-index: 20;
  }
</style>
