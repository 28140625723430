<template>
  <div
    v-if="btnNum === 1"
    class="footerBtn "
    :class="[{'disabled': isDisabled}, className]"
    @click.stop="confirm"
  >{{ confirmText }}</div>
  <div v-else class="footerBtn twoBtns">
    <div class="cancelBtn" @click.stop="cancel">{{ cancelText }}</div>
    <div class="confirmBtn" :class="{'disabled': isDisabled}" @click.stop="confirm">{{ confirmText }}</div>
  </div>
</template>

<script>
// import { } from 'vuex'
export default {
  name: 'FooterBtn',
  components: {},
  props: {
    className: {
      type: String,
      default: 'primary'
    },
    btnNum: {
      type: Number,
      default: 1
    },
    confirmText: {
      type: String,
      default: '确定'
    },
    cancelText: {
      type: String,
      default: '取消'
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    confirm() {
      if (this.isDisabeld) return
      this.$emit('confirm')
    },
    cancel() {
      this.$emit('cancel')
    }
  }

}
</script>

<style lang="less" scoped>
.footerBtn{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: .84rem;
  line-height: .84rem;
  font-size: .24rem;
  font-weight: 400;
  text-align: center;
  padding-bottom: env(safe-area-inset-bottom);
  &.primary{
    background: #2F8AF1;
    color: #fff;
  }
  &.cancel{
    border-top: .02rem #EDEDED solid;
    background: #fff;
    color: #333;
  }
  &.disabled{
    background: #A7CBF4;
  }
  &.twoBtns{
    display: flex;
    >div{
      flex: 1;
    }
    .cancelBtn{
      border-top: .02rem #EDEDED solid;
      background: #fff;
      color: #333;
    }
    .confirmBtn{
      background: #2F8AF1;
      color: #fff;
    }
  }
}
</style>
