import { origin, originV2, originUpload, get } from './config'
import cookies from '@/utils/cookie.js'
export default {
  portAddress(params) { // 平台接口
    return origin(`/default/index`, params)
  },
  getDict(data) {
    return originV2({
      ...data,
      token: cookies.get('token'),
      method: '300010'
    })
  },
  upload(data) {
    const formData = new FormData()
    formData.append('file', data.file)
    formData.append('type', data.type)
    formData.append('token', cookies.get('token'))
    return originUpload(formData)
  },
  getWorkDetail(data) {
    return originV2({
      ...data,
      token: cookies.get('token'),
      method: '200001'
    })
  },
  getDiagnoseList(data) {
    return originV2({
      ...data,
      token: cookies.get('token'),
      method: '200020'
    })
  },
  submitDiagnose(data, method) {
    return originV2({
      ...data,
      token: cookies.get('token'),
      method
    })
  },
  getKnowledgeCategoryObj() {
    return originV2({
      token: cookies.get('token'),
      method: '300001'
    })
  },
  getKnowledgeFolder(data) {
    return originV2({
      ...data,
      token: cookies.get('token'),
      method: '300002'
    })
  },
  getKnowledgeFile(data) {
    return originV2({
      ...data,
      token: cookies.get('token'),
      method: '300003'
    })
  },
  // 获取验证码
  async getCaptcha() {
    try {
      const response = await get('/site/captcha', {
        refresh: 1, // 强制刷新验证码
        _: new Date().getTime(), // 添加时间戳避免缓存
      });

      if (response && response.url) {
        return response; // 返回包含验证码 URL 的数据
      } else {
        throw new Error('验证码生成失败');
      }
    } catch (error) {
      console.error('获取验证码失败:', error);
      throw error; // 将错误抛出以便调用方处理
    }
  }
}
