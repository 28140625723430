<template>
  <!--选项卡-->
  <div>
    <ul>
      <li
        v-for="(item,index) in tabData"
        :key="index"
        :class="item.active == true ? 'active' : ''"
        @click="statsChange(item,index)"
      >
        <span>{{ item.name }}
          <sub v-if="index==0&&nums.Work_finish_num!='0'" class="num">{{ nums.Work_finish_num }}</sub>
          <sub v-if="index==1&&nums.work_diagnosis_num!='0'" class="num">{{ nums.work_diagnosis_num }}</sub>
          <sub v-if="index==2&&nums.work_check_num!='0'" class="num">{{ nums.work_check_num }}</sub>
        </span>
        <sub class="line" />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Tab',
  props: {
    data: {
      type: Array,
      default() {
        return []
      }
    },
    nums: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      tabData: this.data
    }
  },
  created() {
    this.$set(this.tabData[0], 'active', true)
    console.log(this.num)
  },
  methods: {
    statsChange(item, index) {
      this.tabData.forEach(item => {
        this.$delete(item, 'active')
        item.active = 0
      })
      this.$set(this.tabData[index], 'active', true)
      this.$emit('on-click', item, index)
    }
  }
}
</script>

<style scoped lang="less">
div {
  width: 100%;
  background-color: #fff;
  position: fixed;
  top: 0.75rem;
  z-index: 10;
}
div > ul {
  display: flex;
  text-align: center;
  border-top: 0.02rem solid #f3f3f3;
  border-bottom: 0.02rem solid #f3f3f3;
  font-size: 0.24rem;
  li {
    flex: 1;
    padding: 0.27rem 0 0;
    span{
      position: relative;
      .num{
        display: block;
        position: absolute;
        top:-0.1rem;
        right: -0.3rem;
        padding:0.01rem;
        width: 0.25rem;
        height: 0.25rem;
        line-height: 0.25rem;
        border-radius: 50%;
        color: #f3f3f3;
        background-color: #ff3000;
        font-size: 0.2rem;
        text-align: center;
        // border: 0.01rem solid #ff3000;
      }
    }
    .line {
      display: block;
      background: transparent;
      border: 0.01rem solid transparent;
      width: 0.2rem;
      height: 0.02rem;
      margin: 0.16rem auto 0;
    }

  }
}
.active {
  .line {
    border: 0.01rem solid #2F8AF1 !important;
    background: #2F8AF1;
    border-radius:0.02rem;
  }
}
</style>
