<template>
  <div
    v-show="changeShow"
    class="change-show"
    @click.capture="changehidden"
  >
    <div>
      <div class="top">
        <img src="../../assets/images/detail/give.png" alt>
        {{ text }}
      </div>
      <ul>
        <li
          v-for="(item,index) in lauter"
          :key="index"
          :class="item.active == true ? 'active' : ''"
          @click="change(item,index)"
        >{{ item.store_name?item.store_name:item.nickname }}</li>
      </ul>
      <div class="btn">
        <span @click="cancel">取消</span>
        <span @click="confirm">确定</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BtmModal',
  props: {
    value: Boolean,
    data: {
      type: Array,
      default() {
        return []
      }
    },
    text: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      changeShow: this.value,
      lauter: this.data,
      person: ''
    }
  },
  watch: {
    value(val) {
      this.changeShow = val
    }
  },
  methods: {
    change(item, index) {
      this.$set(this.lauter[index], 'active', true)
      this.lauter.forEach(item => {
        this.$delete(item, 'active')
        item.active = 0
      })
      this.$set(this.lauter[index], 'active', true)
      this.person = item
    },
    changehidden() {
      this.$emit('on-change')
    },
    cancel() {
      document.getElementsByTagName('body')[0].style.overflowY = 'auto'
      this.lauter.forEach(item => {
        this.$delete(item, 'active')
        item.active = 0
      })
      this.person = ''
      this.$emit('on-cancel')
    },
    confirm() {
      if (this.person) {
        this.$emit('on-click', this.person)
        this.lauter.forEach(item => {
          this.$delete(item, 'active')
          item.active = 0
        })
        document.getElementsByTagName('body')[0].style.overflowY = 'auto'
      }
    }
  }
}
</script>

<style scoped lang="less">
.change-show {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.67);
  width: 100%;
  // height: 100vh;
  z-index: 10;
  & > div {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #fff;
    border-radius: 0.1rem 0.1rem 0 0;
    .top {
      height: 1.18rem;
      padding: 0.27rem;
      box-sizing: border-box;
      box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.1);
      img {
        width: 0.63rem;
        vertical-align: middle;
        margin-right: 0.15rem;
        position: relative;
        top: -0.05rem;
      }
    }
    & > ul {
      max-height: 3.5rem;
      overflow-y: auto;
      li {
        border-bottom: 0.01rem solid #f3f3f3;
        color: #333;
        padding: 0 0.27rem;
        font-size: 0.24rem;
        height: 0.85rem;
        line-height: 0.85rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: break-all
      }
      .active {
        color: #2f8af1;
        background-color: #f3f3f3;
      }
    }
    & > .btn {
      height: 0.8rem;
      line-height: 0.8rem;
      width: 100%;
      span {
        float: left;
        display: inline-block;
        text-align: center;
        width: 50%;
        color: #666666;
        font-size: 0.24rem;
      }
      & > :last-child {
        background-color: #2f8af1;
        border-top: 0.02rem solid #2f8af1;
        color: #fff;
      }
    }
  }
}
</style>
