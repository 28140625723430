import Storage from '@/utils/storge'
import http from '@/api/request'
import api from '@/api'

const state = {
  token: Storage.getItem('token'),
  userInfo: {}
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
    Storage.setItem('token', token)
  },
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = userInfo
    Storage.setItem('userInfo', JSON.stringify(userInfo))
  }
}

const actions = {
  // user login
  login({ commit, dispatch }, params) {
    return new Promise((resolve, reject) => {
      http.$post(api.login, params).then(res => {
        commit('SET_TOKEN', res.data.token)
        dispatch('getUserInfo')
        resolve(res)
      }).catch(res => {
        reject(res)
      })
    })
  },
  // get user info
  getUserInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      http.$post(api.getUserInfo).then(res => {
        commit('SET_USER_INFO', res.data)
        resolve(res.data)
      }).catch(res => {
        reject()
      })
    })
  },
  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit('SET_TOKEN', '')
      commit('SET_USER_INFO', {})
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
