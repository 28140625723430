<template>
  <div
    ref="div"
    class="ys-float-btn"
    :style="{'right':myRight+'rem'}"
    @click="onBtnClicked"
  >
    <img src="../../assets/images/back_page.png" alt>
  </div>
</template>
<script>
export default {
  name: 'FloatImgBtn',
  props: {
  },
  data() {
    return {
      myRight: -1
    }
  },
  created() {

  },
  mounted() {
    window.addEventListener('scroll', this.handleScrollStart)
    this.$nextTick(() => {
      const div = this.$refs.div
      div.addEventListener('touchstart', () => {
        div.style.transition = 'none'
      })
      div.addEventListener('touchmove', (e) => {
        if (e.targetTouches.length === 1) {
          const touch = event.targetTouches[0]
          this.left = touch.clientX - this.itemWidth / 2
          this.top = touch.clientY - this.itemHeight / 2
        }
      })
      div.addEventListener('touchend', () => {
        div.style.transition = 'all 0.3s'
        if (this.left > this.clientWidth / 2) {
          this.left = this.clientWidth - this.itemWidth - this.gapWidth
        } else {
          this.left = this.gapWidth
        }
      })
    })
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScrollStart)
  },
  methods: {
    onBtnClicked() {
      this.$router.replace({
        path: '/home'
      })
    },
    handleScrollStart() {
      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.handleScrollEnd()
      }, 2000)
      this.myRight = 0.2
    },
    handleScrollEnd() {
      this.myRight = -1
    }
  }
}
</script>
<style lang="less" scoped>
  .ys-float-btn{
    width:1rem;
    height: 1rem;
    z-index: 20;
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    right:0.2rem;
    bottom: 2rem;
    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
      // margin-bottom: 3px;
    }
    p{
      font-size:7px;
    }
  }
</style>
