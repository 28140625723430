<template>
  <mt-popup
    v-model="isShowPopup"
    position="bottom"
    style="width: 100%; border-radius: .1rem .1rem 0 0;"
  >
    <div class="bottomWrapper">
      <div class="inner">
        <img :src="require('../../assets/images/common/'+ (icon || 'delete_icon') +'.png')">
        <div v-if="desc" class="hGroup">
          <h3 class="">{{ title }}</h3>
          <div class="grey9">{{ desc }}</div>
        </div>
        <h3 v-else class="hGroup">{{ title }}</h3>
      </div>

      <footerBtn
        :btn-num="2"
        :confirm-text="confirmText"
        cancel-text="取消"
        @confirm="$emit('confirm')"
        @cancel="$emit('closePopup')"
      />
    </div>
  </mt-popup>

</template>

<script>
import FooterBtn from '@/components/footerBtn'
export default {
  name: 'ConfirmModal',
  components: { FooterBtn },
  props: {
    showPopup: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    desc: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'delete_icon'
    },
    confirmText: {
      type: String,
      default: '确定'
    }
  },
  data() {
    return {
      isShowPopup: false
    }
  },
  watch: {
    showPopup() {
      this.isShowPopup = this.showPopup
    },
    isShowPopup() {
      if (!this.isShowPopup) {
        this.$emit('closePopup')
      }
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="less">
.bottomWrapper{
  width: 100%;

  .inner{
    padding: .27rem .27rem 1.5rem;
    display: flex;
    align-items: center;
    img{
      width: .63rem;
      height: .63rem;
      margin-right: .15rem;
    }
    //.hGroup{
    //  line-height: .43rem;
    //  div:first-child{
    //    padding: .1rem -0
    //  }
    //}
    h3{
      font-size: .31rem;
      font-weight: normal;
      padding: .1rem 0;
      line-height: .43rem;
    }
  }
}
</style>
