import axios from 'axios'
// import md5 from 'js-md5'
import router from '../router'
import message from '../base/message/message.js'
import cookies from '@/utils/cookie.js'
import Vue from 'vue'
console.log(process.env)
const API_URL_V1 = process.env.VUE_APP_BASE_API // 接口地址
const API_URL_V2 = process.env.VUE_APP_BASE_API_V2 // 接口地址
const noAuthStatus = ['218', 212, '214', '213']
// const server = '/v1'

export function origin(url, data) {
  // url = API_URL_V1 + url
  url = API_URL_V2
  return new Promise((resolve, reject) => {
    if (!navigator.onLine) {
    } else {
      Vue.prototype.$Indicator.open()
      axios.post(url, data, {
        timeout: 15000
      }).then(response => {
        Vue.prototype.$Indicator.close()
        if (response.data.status === 200) {
          resolve(response.data)
        } else if (noAuthStatus.indexOf(response.data.status) > -1) {
          cookies.remove('token')
          message.installMessage({ message: response.data.msg })
          router.push({ path: '/login' })
          reject('noAuth')
        } else {
          message.installMessage({ message: response.data.msg })
          reject(response.data)
        }
      }).catch(error => {
        Vue.prototype.$Indicator.close()
        reject(error)
      })
    }
  })
}

export function originV2(data) {
  return new Promise((resolve, reject) => {
    if (!navigator.onLine) {
    } else {
      Vue.prototype.$Indicator.open()
      axios.post(API_URL_V2, data, {
        timeout: 60000
      }).then(response => {
        Vue.prototype.$Indicator.close()
        if (response.data.status === 200) {
          resolve(response.data)
        } else if (noAuthStatus.indexOf(response.data.status) > -1) {
          cookies.remove('token')
          message.installMessage({ message: response.data.msg })
          router.push({ path: '/login' })
          reject('noAuth')
        } else {
          message.installMessage({ message: response.data.msg })
          reject(response.data)
        }
      }).catch(error => {
        Vue.prototype.$Indicator.close()
        reject(error)
      })
    }
  })
}


export function originUpload(data) {
  return new Promise((resolve, reject) => {
    if (!navigator.onLine) {
    } else {
      axios.post(API_URL_V2 + '/upload', data, {
        timeout: 60000,
        headers: {
          'Content-Type': 'multipart/form-data',
          Auth: 'Ez8nTLZiebG1'
        }
      }).then(response => {
        const res = response.data
        if (res.code === 0) {
          resolve(res)
        } else {
          if (res.code === 1005 && res.message === '暂无数据') {
            console.log(res)
          } if (res.code === '104' || res.code === '103') {
            cookies.remove('token')
            message.installMessage({ message: res.msg })
            router.push({ path: '/login' })
          } else {
            message.installMessage({ message: res.message })
          }
          return Promise.reject(res)
        }
      }).catch(error => {
        reject(error)
      })
    }
  })
}

// 新增简单的 GET 请求方法
export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    if (!navigator.onLine) {
      reject('Network Error'); // 网络不可用时直接返回错误
    } else {
      axios
        .get(`${API_URL_V2}${url}`, {
          params, // 查询参数对象
          withCredentials: true, // 确保携带 Cookie（适用于跨域场景）
          headers: {
            'Cache-Control': 'no-cache', // 避免缓存
            Pragma: 'no-cache',
            Expires: '0',
          },
        })
        .then(response => {
          resolve(response.data); // 返回响应数据
        })
        .catch(error => {
          reject(error); // 捕获并返回错误
        });
    }
  });
}

// 对md5加密
function getAuth(config) {
  let str = ''
  const paramsArr = Object.keys(config.params ? config.params : '').sort()
  for (const i in paramsArr) {
    str += paramsArr[i] + '=' + config.params[paramsArr[i]] + '&'
  }
  str = str.substring(0, str.length - 1)
  str += config.method + 'p^G&D8kJ!1Aq'
  return md5(str)
}
