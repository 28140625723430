// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'
import $scroll from '@/utils/loading'
Vue.prototype.$scroll = $scroll
import '@/utils/components'
import '@/assets/css/resets.less'
Vue.config.productionTip = false

import Vconsole from 'vconsole'
if (process.env.NODE_ENV === 'development') {
  new Vconsole()
}

// 直接标签组件
const requireComponent = require.context(
  './base', true, /[\w-]+\.vue$/
)
requireComponent.keys().forEach(filePath => {
  const componentConfig = requireComponent(filePath)
  const fileName = filePath.replace(/^\.\/(.*)\.\w+$/, '$1').split('/')
  const componentName = upperFirst(
    camelCase(fileName[fileName.length - 1])
  )
  Vue.component(componentName, componentConfig.default || componentConfig)
})
import { Uploader, Popover, ImagePreview, Popup } from 'vant'
Vue.use(Uploader)
Vue.use(Popover)
Vue.use(Popup)
Vue.prototype.$imagePreview = ImagePreview
Vue.mixin({
  methods: {
    goBack() {
      this.$router.go(-1)
    }
  }
})

/* eslint-disable no-new */
// new Vue({
//   el: '#app',
//   router,
//   components: { App },
//   template: '<App/>'
// })
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
